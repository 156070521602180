.btnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0099cc !important;
  border: none !important;
  color: white !important;
  min-height: 30px;
  border-radius: 17px !important;
  font-size: 14px !important;
}
