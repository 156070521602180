/* #wrapper {
  overflow-x: hidden;
} */

.whiteHeader {
  color: #003366;
  background: white;
  border: 1px solid #003366;
  height: 1.8rem;
}

.sidebarText {
  margin-left: 15%;
  padding-top: 1%;
}

#sidebar-wrapper .list-group {
  width: 15rem;
  background: #003366 !important;
}

#sidebar-wrapper .list-group-item:hover {
  background: white !important;
}

.inactive {
  text-decoration: none !important;
}

.sidebarLogo {
  width: 65%;
}

.logoutButton {
  position: absolute;
  bottom: 15px;
  color: #8099b3;
  border-top: white solid 2px;
}

.logoutButton div:hover {
  color: white;
}

.webButton {
  position: absolute;
  bottom: 60px;
  color: white;
}

.webButton div:hover {
  color: #c06;

}

.submenuText {
  margin-left: 50px !important;
}

.iconClass {
  margin-left: 0.1rem;
}

.changePassword {
  position: absolute;
  bottom: 11%;
  cursor: pointer;
  background: #003366 !important;
  color: white;
  height: 8%;
  justify-content: center;
  align-content: center;
  padding-right: 2%;
}

@media only screen and (max-width: 768px) {
  .sidebar-heading {
    display: none;
  }
  .logoutButton {
    visibility: hidden;
  }
  .changePassword {
    visibility: hidden;
  }
}

@media only screen and (min-width: 769px) {
  .responsiveLogout {
    display: none;
  }
}
