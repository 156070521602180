.loginContainer {
  height: 100vh;
  width: 100%;
  display: flex;
}

.divImpulsoLogo {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardLogin {
  border-radius: 8px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #003366 !important;
  color: white;
}

.loginLogo {
  width: 50%;
}

.loginButton {
  background-color: #ffffff;
  color: #003366;
  width: 60%;
  height: 2.5rem;
  border-radius: 8px;
  border: none;
  font-weight: 700;
}

.loginButton2 {
  background-color: #12385e;
  color: white;
  width: 60%;
  height: 2.5rem;
  border-radius: 8px;
  border: 2px solid white;
  font-weight: 700;
}

.input80 {
  width: 80% !important;
  height: 2.5rem !important;
  border-radius: 8px !important;
}

.input100 {
  width: 100% !important;
  height: 2.5rem !important;
  border-radius: 8px !important;
}

.resetPasswordContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.cardResetPassword {
  width: 30vw;
  border-radius: 8px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  justify-content: center;
  align-items: center;
  background: #003366 !important;
  color: white;
  box-shadow: 0px 1px 4px 0px #000000;
  padding: 3%;
}

.textCardPassword {
  color: #f1f1f1;
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  .cardLogin {
    width: 100vw;
    background: #003366;
    color: white;
  }
  .divImpulsoLogo {
    display: none;
  }
  .loginLogo {
    width: 70%;
  }
  .cardResetPassword {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
